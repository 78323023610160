import { Box, Flex, Text } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import React from "react";

const Legend = () => {
  const { width } = useViewportSize();

  const legendItems = [
    {
      text: "Enregistrement",
      borderColor: "#53B5D8",
      backgroundColor: "#C8E7F7",
    },
    {
      text: "Réunion",
      borderColor: "#60B286",
      backgroundColor: "#C2EEBA",
    },
    {
      text: "Rédaction réunion",
      borderColor: "#fff70e",
      backgroundColor: "#fffeeb",
    },
    {
      text: "Autre",
      borderColor: "#8276D1",
      backgroundColor: "#DBD6FA",
    },
    {
      text: "Absence",
      borderColor: "#CACACA",
      backgroundColor: "#F9FAFB",
    },
    {
      text: "Journée sans présence",
      borderColor: "#60B286",
      backgroundColor: "#fff",
    },
    {
      text: "Journée de coworking",
      borderColor: "#f5a623",
      backgroundColor: "#fff",
    },
    {
      text: "Journée au bureau",
      borderColor: "#ff91ee",
      backgroundColor: "#fff",
    },
  ];

  const filteredItems = legendItems.filter(
    (item) =>
      width >= 1200 ||
      (item.text !== "Journée sans présence" &&
        item.text !== "Journée de coworking" &&
        item.text !== "Journée au bureau")
  );

  return (
    <Flex>
      {filteredItems.map((elem, index) => (
        <Flex mr={24} key={index}>
          <Box
            style={{
              width: 20,
              height: 20,
              marginRight: 10,
              borderRadius: 32,
              marginTop: 2,
              backgroundColor: elem.backgroundColor,
              border: `1px solid ${elem.borderColor}`,
            }}
          />
          <Text fw={400} style={{ color: "#333333" }}>
            {elem.text}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

export default Legend;
