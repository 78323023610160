import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  Group,
  LoadingOverlay,
  Modal,
  SegmentedControl,
  Select,
  Stack,
  Text,
  TextInput,
  MultiSelect,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { postDeliverBillDelete, postTasks } from "../apiRoutes";
import { DatePicker, TimeInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { TaskDto, TaskType, UserRole } from "../dto/user";
import { AxiosInstance } from "axios";
import {
  calculateExpectedTime,
  combineDateAndTime,
  convertToClockTime,
  isTaskTypeFixed,
  isTaskTypeOnlyPlanning,
  ceilToNearestHalf,
} from "../utils";
import { useDisclosure } from "@mantine/hooks";
import MultipleDatesInput from "./MultipleDatesInput";
import { UserContext } from "../UserContext";
import { TaskDurationSelectOptions, TimeSelectOptions } from "./selectOptions";
import RichText from "./RichText";

const TaskForm = ({
  opened,
  open,
  close,
  isPlanning,
  isRecordings,
  api,
  setRefreshCounter,
  users = [],
  redactors,
  setIsPlanning,
  tasks,
  resetFormCounter,
}: {
  opened: boolean;
  open: () => void;
  close: () => void;
  isPlanning: boolean;
  isRecordings: boolean;
  api: AxiosInstance;
  setRefreshCounter: React.Dispatch<React.SetStateAction<number>>;
  users?: {
    label: string;
    value: string;
    places: string;
    processinglevel: string;
    averagmeetingduration: number;
  }[];
  redactors: {
    label: string;
    value: string;
  }[];
  setIsPlanning?: React.Dispatch<React.SetStateAction<boolean>>;
  tasks: TaskDto[];
  resetFormCounter?: number;
}) => {
  const [loading, setLoading] = useState(false);
  const [planMultipleDates, setPlanMultipleDates] = useState(false);
  const [multipleDates, setMultipleDates] = useState<string[]>([]);
  const [selectedCustomerPlaces, setSelectedCustomerPlaces] =
    React.useState("");
  const { user } = useContext(UserContext);
  const [
    openedDeliverBillModal,
    { open: openDeliverBillModal, close: closeDeliverBillModal },
  ] = useDisclosure(false);
  const [deliverBillDeleteModalType, setDeliverBillDeleteModalType] =
    React.useState<"Facturer" | "Livrer" | "Supprimer">("Facturer");
  const [allRedactors, setAllRedactors] = useState(false);
  const [multipleRedactors, setMultipleRedactors] = useState(false);
  const [selectedRedactors, setSelectedRedactors] = useState<string[]>([]);
  const form = useForm<
    TaskDto & { schedulingType: "fixed" | "auto" | "end-fixed" | "begin-fixed" }
  >({
    initialValues: {
      meetingDate: "",
      format: "",
      duration: "",
      comment: "",
      commentRedaction: "",
      commentRedactor: "",
      instructions: "",
      place: "",
      type: isRecordings ? TaskType.recordingWriting : TaskType.meeting,
      state: undefined,
      date: new Date(),
      customerId: undefined,
      redactorId: undefined,
      startDate: undefined,
      startTime: undefined,
      endDate: undefined,
      endTime: undefined,
      slotDuration: undefined,
      schedulingType: "fixed",
      deliveryDate: undefined,
      timeAtTheCustomer: undefined,
      subType: undefined,
    },
    validate: {
      customerId: (value, values) =>
        !value &&
        values.type !== TaskType.break &&
        values.type !== TaskType.otherFixed &&
        values.type !== TaskType.otherFlexible
          ? "Champ requis"
          : null,
      redactorId: (value, values) =>
        (values.type === TaskType.break ||
          values.type === TaskType.otherFixed ||
          values.type === TaskType.otherFlexible) &&
        !value
          ? "Champ requis"
          : null,
      slotDuration: (value, values) =>
        (values.schedulingType !== "fixed" || !isTaskTypeFixed(values.type)) &&
        !value &&
        values.redactorId
          ? "Champ requis"
          : null,
      startTime: (value, values) =>
        (values.schedulingType === "begin-fixed" && value === undefined) ||
        (values.redactorId &&
          values.schedulingType === "fixed" &&
          value === undefined)
          ? "Champ requis"
          : null,
      startDate: (value, values) =>
        (values.schedulingType === "begin-fixed" && value === undefined) ||
        (values.redactorId &&
          values.schedulingType === "fixed" &&
          value === undefined)
          ? "Champ requis"
          : null,
      endTime: (value, values) =>
        (values.schedulingType === "end-fixed" && value === undefined) ||
        (values.redactorId &&
          values.schedulingType === "fixed" &&
          value === undefined)
          ? "Champ requis"
          : null,
      endDate: (value, values) =>
        (values.schedulingType === "end-fixed" && value === undefined) ||
        (values.redactorId &&
          values.schedulingType === "fixed" &&
          value === undefined)
          ? "Champ requis"
          : null,
    },
  });

  useEffect(() => {
    const handlePlanTask = (e: any) => {
      const { taskId, type, buttonType } = e.detail;

      const ensureDateObject = (date: any): Date | undefined => {
        return date ? new Date(date) : undefined;
      };
      const adjustToLocalDate = (date: Date | undefined) => {
        if (!date) return undefined;
        const timezoneOffset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() + timezoneOffset);
      };
      const extractStartTime = (date: Date | undefined) => {
        if (!date) return undefined;
        const timePart = date.toISOString().split("T")[1].split(".")[0];
        return timePart + ".000Z";
      };

      if (
        taskId === 0 &&
        (type === TaskType.break ||
          type === TaskType.otherFlexible ||
          type === TaskType.otherFixed)
      ) {
        console.log("type", type);
        form.reset();
        form.setFieldValue("type", type);
        form.setFieldValue("startTime", "00:00:00.000Z");
        form.setFieldValue("endTime", "07:00:00.000Z");
        if (type === TaskType.otherFlexible) {
          form.setFieldValue("schedulingType", "auto");
        }
      } else {
        const taskToPlan = tasks.find((task) => task.id === taskId);
        const estimatedTime = JSON.stringify(
          ceilToNearestHalf(
            calculateExpectedTime(
              users?.find(
                (user) => user.value === taskToPlan?.customerId?.toString()
              )?.processinglevel ?? "",
              taskToPlan?.duration
            )
          )
        );
        console.log("estimatedTime", estimatedTime, taskToPlan?.duration);
        console.log(
          "taskIdd",
          taskId,
          taskToPlan,
          tasks.length,
          taskToPlan?.type,
          estimatedTime,
          taskToPlan?.slotDuration,
          typeof taskToPlan?.slotDuration,
          taskToPlan?.slotDuration ??
            (taskToPlan?.type === TaskType.meeting &&
            taskToPlan?.customer?.averageMeetingDuration
              ? taskToPlan?.customer?.averageMeetingDuration?.toString() ??
                "3.5"
              : "3.5")
        );
        let slotDuration = undefined;
        if (taskToPlan?.slotDuration) {
          slotDuration = taskToPlan.slotDuration;
        } else if (
          taskToPlan?.type === TaskType.meeting &&
          taskToPlan?.customer?.averageMeetingDuration
        ) {
          slotDuration = taskToPlan.customer.averageMeetingDuration.toString();
        } else if (
          taskToPlan?.type === TaskType.meeting &&
          taskToPlan?.customer?.averageMeetingDuration
        ) {
          slotDuration = "3.5";
        } else {
          slotDuration = estimatedTime;
        }
        console.log("slotDuration", slotDuration);
        form.setValues({
          ...taskToPlan,
          comment: taskToPlan?.comment ?? undefined,
          commentRedaction: taskToPlan?.commentRedaction ?? undefined,
          meetingDate: taskToPlan?.meetingDate ?? undefined,
          state: taskToPlan?.state ?? undefined,
          redactorId: taskToPlan?.redactorId?.toString(),
          customerId: taskToPlan?.customerId?.toString(),
          startDate: adjustToLocalDate(ensureDateObject(taskToPlan?.startDate)),
          endDate: adjustToLocalDate(ensureDateObject(taskToPlan?.endDate)),
          startTime: extractStartTime(ensureDateObject(taskToPlan?.startDate)),
          endTime: extractStartTime(ensureDateObject(taskToPlan?.endDate)),
          schedulingType: isTaskTypeFixed(taskToPlan?.type) ? "fixed" : "auto",
          slotDuration,
        });
        setSelectedCustomerPlaces(taskToPlan?.customer?.places ?? "");
      }

      setIsPlanning && setIsPlanning(true);
      if (buttonType === "Livrer" || buttonType === "Facturer") {
        openDeliverBillModal();
        setDeliverBillDeleteModalType(buttonType);
      } else {
        open();
      }
    };

    window.addEventListener("planTask", handlePlanTask);
    return () => {
      window.removeEventListener("planTask", handlePlanTask);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, tasks]);

  useEffect(() => {
    form.reset();
    form.setFieldValue(
      "type",
      isRecordings ? TaskType.recordingWriting : TaskType.meeting
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFormCounter, isRecordings]);

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Modal
        opened={openedDeliverBillModal}
        onClose={closeDeliverBillModal}
        title={
          deliverBillDeleteModalType === "Facturer"
            ? "Confirmer la facturation"
            : deliverBillDeleteModalType === "Supprimer"
            ? "Confirmer la suppression définitive de la tâche"
            : "Confirmer la livraison et mettre à jour la date de livraison"
        }
      >
        <Center>
          <Button
            color={
              deliverBillDeleteModalType === "Supprimer" ? "red" : undefined
            }
            onClick={async () => {
              setLoading(true);
              await postDeliverBillDelete(
                api,
                form.values.id ?? 0,
                deliverBillDeleteModalType
              );
              setRefreshCounter((e) => e + 1);
              form.reset();
              closeDeliverBillModal();
              setLoading(false);
            }}
          >
            {deliverBillDeleteModalType === "Facturer"
              ? "Confirmer"
              : deliverBillDeleteModalType === "Supprimer"
              ? "Supprimer"
              : "Mettre à jour la date de livraison"}
          </Button>
        </Center>
      </Modal>
      <Modal
        opened={opened}
        onClose={close}
        title={
          (isPlanning
            ? form.values.type === TaskType.otherFixed
              ? "Plannifier une tâche Autre Fixe"
              : form.values.type === TaskType.otherFlexible
              ? "Plannifier une tâche Autre Flexible"
              : "Planifier"
            : isRecordings
            ? "Nouvel enregistrement à planifier"
            : "Nouvelle réunion à planifier") +
          (form.values.id ? ` (${JSON.stringify(form.values.id)})` : "")
        }
      >
        <form
          onSubmit={form.onSubmit(async (values) => {
            const startDate = combineDateAndTime(
              values.startDate,
              values.startTime
            );
            const endDate = combineDateAndTime(values.endDate, values.endTime);

            if (
              startDate &&
              endDate &&
              endDate <= startDate &&
              form.values.schedulingType === "fixed"
            ) {
              alert("La fin de la tâche doit être après le début de la tâche");
              return;
            }

            const redactionTaskTimeForMeeting = ceilToNearestHalf(
              calculateExpectedTime(
                users?.find((user) => user.value === form.values.customerId)
                  ?.processinglevel ?? "",
                convertToClockTime(
                  users?.find((user) => user.value === form.values.customerId)
                    ?.averagmeetingduration ?? 0
                )
              )
            );

            setLoading(true);
            try {
              const redactorsToProcess = allRedactors
                ? redactors.map((r) => r.value)
                : multipleRedactors
                ? selectedRedactors
                : [values.redactorId];

              // Créer un tableau de promesses pour toutes les tâches
              const taskPromises = redactorsToProcess.map(
                async (redactorId) => {
                  const submitData = {
                    ...values,
                    startDate,
                    endDate,
                    redactionTaskTimeForMeeting,
                    redactorId: redactorId ? +redactorId : undefined,
                    customerId: values.customerId
                      ? +values.customerId
                      : undefined,
                    slotDuration: values.slotDuration,
                    multipleDates: planMultipleDates
                      ? multipleDates
                      : undefined,
                  };
                  delete submitData.startTime;
                  delete submitData.endTime;

                  try {
                    const ret = await postTasks(api, submitData);
                    if (
                      ret.data === "interferance" ||
                      ret.data === "interferance_flex"
                    ) {
                      return {
                        redactorId,
                        redactorLabel: redactors.find(
                          (r) => r.value === redactorId
                        )?.label,
                        error: ret.data,
                      };
                    }
                    return { redactorId, success: true };
                  } catch (error) {
                    return {
                      redactorId,
                      redactorLabel: redactors.find(
                        (r) => r.value === redactorId
                      )?.label,
                      error: "error",
                    };
                  }
                }
              );

              // Exécuter toutes les promesses en parallèle
              const results = await Promise.all(taskPromises);

              // Traiter les résultats
              const successes = results.filter((result) => result.success);
              const errors = results.filter((result) => !result.success);

              // S'il y a eu des erreurs, on les affiche
              if (errors.length > 0) {
                const errorMessages = errors.map((error) => {
                  if (error.error === "interferance") {
                    return `La tâche pour ${error.redactorLabel} chevauche une autre tâche qui ne peut pas être bougée.`;
                  } else if (error.error === "interferance_flex") {
                    return `La tâche flexible pour ${error.redactorLabel} doit démarrer au début d'une autre tâche flexible ou dans un créneau disponible.`;
                  }
                  return `Erreur pour ${error.redactorLabel}`;
                });
                alert(
                  `${
                    successes.length
                  } tâche(s) créée(s) avec succès.\n\nErreurs pour ${
                    errors.length
                  } tâche(s):\n\n${errorMessages.join("\n")}`
                );
              }

              // On rafraîchit s'il y a eu au moins une tâche créée
              if (successes.length > 0) {
                setRefreshCounter((e) => e + 1);
              }

              // On ferme le modal uniquement si toutes les tâches ont été créées
              if (errors.length === 0) {
                form.reset();
                close();
                setPlanMultipleDates(false);
                setMultipleDates([]);
                setAllRedactors(false);
                setMultipleRedactors(false);
                setSelectedRedactors([]);
              }
            } catch (error) {
              console.error("Erreur lors de la création des tâches:", error);
              alert("Une erreur est survenue lors de la création des tâches");
            } finally {
              setLoading(false);
            }
          })}
        >
          {/* {JSON.stringify(form.values.type)} */}
          {(users ?? []).length > 0 && (
            <Select
              label="Code client"
              placeholder="Ajouter le code client"
              {...form.getInputProps("customerId")}
              data={users ?? []}
              onChange={(value) => {
                const user = users?.find((user) => user.value === value);
                console.log(user, value);
                form.setFieldValue("customerId", value ?? "");
                setSelectedCustomerPlaces(user?.places ?? "");
              }}
              mt={10}
              searchable
              withAsterisk
            />
          )}
          {!isTaskTypeOnlyPlanning(form.values.type) && (
            <Select
              label="Statut de la tâche"
              placeholder="Sélectionnez le statut de la tâche"
              {...form.getInputProps("state")}
              data={[
                { value: "documentsReceived", label: "📄 Documents reçus" },
                { value: "readyToStart", label: "▶️ Prêt à démarrer" },
                {
                  value: "validatedByRedactor",
                  label: "✔️ Validé par le rédacteur",
                },
                { value: "delivered", label: "✉️ Livré" },
                { value: "billed", label: "✅ Facturé" },
              ]}
              mt={10}
              searchable
              clearable
            />
          )}
          {form.values.customerId &&
          !isRecordings &&
          form.values.type === TaskType.meeting ? (
            <Select
              label="Lieu"
              data={selectedCustomerPlaces.split("§").map((place) => ({
                value: place,
                label: place,
              }))}
              placeholder="Sélectionnez l'emplacement de la réunion"
              {...form.getInputProps("place")}
              mt={10}
              searchable
            />
          ) : null}
          {!isPlanning && form.values.type === "meeting" && (
            <Checkbox
              mt={10}
              label="Planifer plusieurs dates"
              checked={planMultipleDates}
              onChange={(event) =>
                setPlanMultipleDates(event.currentTarget.checked)
              }
            />
          )}
          <Flex gap={14}>
            {isRecordings && (
              <TimeInput
                label="Durée de l'enregistrement"
                placeholder="Ajouter le durée de l'enregistrement"
                // description="exemple: 01:35"
                {...form.getInputProps("duration")}
                mt={10}
                w="100%"
              />
            )}
            {!isTaskTypeOnlyPlanning(form.values.type) &&
              (!planMultipleDates ||
                isPlanning ||
                form.values.type !== "meeting") && (
                <TextInput
                  label="Date de la réunion"
                  description="exemple: 1005"
                  placeholder="Ajouter la date de la réunion (exemple 3001)"
                  {...form.getInputProps("meetingDate")}
                  mt={10}
                  w="100%"
                />
              )}
            <TextInput
              label="Commentaire Rédacteurs"
              placeholder="Ajouter un commentaire rédacteurs"
              {...form.getInputProps("commentRedaction")}
              mt={10}
              w="100%"
            />
          </Flex>
          {planMultipleDates && !isPlanning && (
            <MultipleDatesInput
              multipleDates={multipleDates}
              setMultipleDates={setMultipleDates}
            />
          )}
          <Flex gap={14}>
            <RichText
              label="Comm. Administrateurs"
              placeholder="Ajouter un commentaire administrateurs"
              value={form.values.comment}
              onChange={(value) => form.setFieldValue("comment", value)}
            />
          </Flex>
          {(form.values.type === TaskType.otherFlexible ||
            form.values.type === TaskType.otherFixed) && (
            <Select
              label="Sous-catégorie de tâche"
              placeholder="Sélectionnez une sous-catégorie de tâche"
              {...form.getInputProps("subType")}
              data={[
                { value: "relectures", label: "Relectures" },
                { value: "apv", label: "APV" },
                { value: "v2", label: "V2" },
                { value: "formation", label: "Formation" },
                { value: "delegation", label: "Délégation" },
              ]}
              mt={10}
              clearable
              maxDropdownHeight={130}
              searchable
            />
          )}
          {isPlanning && (
            <>
              {!isTaskTypeOnlyPlanning(form.values.type) && <Divider my="sm" />}
              {TaskType.meeting === form.values.type &&
                form.values.redactorId && (
                  <Flex gap={14}>
                    <TimeInput
                      label="Temps de présence"
                      placeholder="Ajouter le "
                      {...form.getInputProps("timeAtTheCustomer")}
                      mt={10}
                      w="100%"
                    />
                    <TimeInput
                      label="Durée de la réunion"
                      placeholder="Ajouter le "
                      {...form.getInputProps("duration")}
                      mt={10}
                      w="100%"
                    />
                    <TextInput
                      label="Commentaire du rédacteur"
                      placeholder="Ajouter un commentaire du rédacteur"
                      {...form.getInputProps("commentRedactor")}
                      mt={10}
                      w="100%"
                    />
                  </Flex>
                )}
              <Group>
                {multipleRedactors ? (
                  <MultiSelect
                    label="Rédacteurs"
                    placeholder="Choisissez les rédacteurs"
                    data={redactors}
                    mt={10}
                    searchable
                    withAsterisk
                    value={selectedRedactors}
                    onChange={(value) => {
                      setTimeout(() => {
                        setSelectedRedactors(value);
                        // On met à jour redactorId avec une valeur non vide si au moins 2 rédacteurs sont sélectionnés
                        form.setFieldValue(
                          "redactorId",
                          value.length >= 2 ? "multiple" : ""
                        );
                      }, 0);
                    }}
                    style={{ width: "100%" }}
                    zIndex={1000000}
                    maxDropdownHeight={150}
                  />
                ) : (
                  <Select
                    label="Rédacteur"
                    placeholder="Choisissez un rédacteur"
                    {...form.getInputProps("redactorId")}
                    data={redactors}
                    mt={10}
                    searchable
                    withAsterisk
                    disabled={allRedactors}
                    zIndex={1000000}
                    maxDropdownHeight={150}
                  />
                )}
                {!form.values.id &&
                  (form.values.type === TaskType.break ||
                    form.values.type === TaskType.otherFixed) && (
                    <Stack
                      style={{ gap: 5 }}
                      mt={multipleRedactors ? undefined : "md"}
                    >
                      {!multipleRedactors && (
                        <Checkbox
                          label="Tous les rédacteurs"
                          checked={allRedactors}
                          onChange={(event) => {
                            setAllRedactors(event.currentTarget.checked);
                            if (event.currentTarget.checked) {
                              form.setFieldValue("redactorId", "all"); // Valeur spéciale pour tous les rédacteurs
                              setMultipleRedactors(false);
                            } else {
                              form.setFieldValue("redactorId", "");
                            }
                          }}
                        />
                      )}
                      {!allRedactors && (
                        <Checkbox
                          label="Plusieurs rédacteurs"
                          checked={multipleRedactors}
                          onChange={(event) => {
                            setMultipleRedactors(event.currentTarget.checked);
                            if (event.currentTarget.checked) {
                              form.setFieldValue("redactorId", "");
                              setAllRedactors(false);
                              setSelectedRedactors([]);
                            } else {
                              setSelectedRedactors([]);
                              form.setFieldValue("redactorId", "");
                            }
                          }}
                        />
                      )}
                    </Stack>
                  )}
              </Group>
              {/* Modification de la condition pour afficher les champs de planification */}
              {(form.values.redactorId ||
                selectedRedactors.length >= 2 ||
                allRedactors) && (
                <>
                  {form.values.type !== "break" && (
                    <Text style={{ fontSize: 14 }} mt={10}>
                      {users?.length === 0 // page planning
                        ? `Temps créneau : ${form.values.slotDuration} heures`
                        : form.values.type === TaskType.meeting
                        ? `Durée habituelle de la réunion : ${
                            users?.find(
                              (user) => user.value === form.values.customerId
                            )?.averagmeetingduration
                          } heures`
                        : `Temps estimé : ${calculateExpectedTime(
                            users?.find(
                              (user) => user.value === form.values.customerId
                            )?.processinglevel ?? "",
                            form.values.duration
                          )} heures`}
                    </Text>
                  )}
                  {form.values.type === TaskType.meeting &&
                    users?.length !== 0 && (
                      <Box>
                        <Text style={{ fontSize: 14 }}>
                          Estimation du temps de rédaction :{" "}
                          {calculateExpectedTime(
                            users?.find(
                              (user) => user.value === form.values.customerId
                            )?.processinglevel ?? "",
                            convertToClockTime(
                              users?.find(
                                (user) => user.value === form.values.customerId
                              )?.averagmeetingduration ?? 0
                            )
                          )}
                          {" heures"}
                        </Text>
                        <Text style={{ fontSize: 14 }}>
                          Création d'un temps de rédaction de{" "}
                          {ceilToNearestHalf(
                            calculateExpectedTime(
                              users?.find(
                                (user) => user.value === form.values.customerId
                              )?.processinglevel ?? "",
                              convertToClockTime(
                                users?.find(
                                  (user) =>
                                    user.value === form.values.customerId
                                )?.averagmeetingduration ?? 0
                              )
                            )
                          )}
                          {" heures"}
                        </Text>
                      </Box>
                    )}
                  {/* <Flex>
              <Checkbox label="Création d'un" mt={3} />
            </Flex> */}
                  <Text style={{ fontSize: 14 }} mt={10}>
                    Planification
                    {form.values.type === TaskType.meeting
                      ? " de la réunion"
                      : ""}
                  </Text>
                  <SegmentedControl
                    data={[
                      ...(isTaskTypeFixed(form.values.type)
                        ? [{ label: "Fixé", value: "fixed" }]
                        : [{ label: "Automatique", value: "auto" }]),
                      { label: "Début fix", value: "begin-fixed" },
                      // { label: "Fin fix", value: "end-fixed" },
                    ]}
                    {...form.getInputProps("schedulingType")}
                    fullWidth
                  />
                  {(form.values.schedulingType !== "fixed" ||
                    !isTaskTypeFixed(form.values.type)) && (
                    <Select
                      label="Durée de la tâche"
                      placeholder="Temps alloué pour réaliser la tâche"
                      {...form.getInputProps("slotDuration")}
                      data={TaskDurationSelectOptions}
                      mt={10}
                      searchable
                      withAsterisk
                    />
                  )}
                  {(form.values.schedulingType === "fixed" ||
                    form.values.schedulingType === "begin-fixed") && (
                    <Box>
                      <Text style={{ fontSize: 14 }} mt={10}>
                        Date de début de la tâche
                      </Text>
                      <Flex>
                        <DatePicker
                          placeholder="Date de début de la tâche"
                          {...form.getInputProps("startDate")}
                          mx="auto"
                          mt={10}
                          excludeDate={(date) =>
                            date.getDay() === 0 || date.getDay() === 6
                          }
                        />
                        <Select
                          label="Heure de démarage de la tâche"
                          placeholder="Heure de démarage de la tâche"
                          {...form.getInputProps("startTime")}
                          data={TimeSelectOptions}
                          mt={10}
                          searchable
                          withAsterisk
                        />
                      </Flex>
                    </Box>
                  )}
                  {(form.values.schedulingType === "fixed" ||
                    form.values.schedulingType === "end-fixed") && (
                    <Box>
                      <Text style={{ fontSize: 14 }} mt={10}>
                        Date de fin de la tâche
                      </Text>
                      <Flex>
                        <DatePicker
                          placeholder="Date de fin de la tâche"
                          {...form.getInputProps("endDate")}
                          mx="auto"
                          mt={10}
                          excludeDate={(date) =>
                            date.getDay() === 0 || date.getDay() === 6
                          }
                        />
                        <Select
                          label="Heure de fin de la tâche"
                          placeholder="Heure de fin de la tâche"
                          {...form.getInputProps("endTime")}
                          data={TimeSelectOptions}
                          mt={10}
                          searchable
                          withAsterisk
                        />
                      </Flex>
                    </Box>
                  )}
                </>
              )}
            </>
          )}
          <Center style={{ gap: 16 }}>
            <Button onClick={close} mt={16} variant="default">
              Annuler
            </Button>
            {form.values.id && user?.role === UserRole.administrateur && (
              <Button
                onClick={() => {
                  setDeliverBillDeleteModalType("Supprimer");
                  openDeliverBillModal();
                  close();
                }}
                mt={16}
                color="red"
              >
                Supprimer
              </Button>
            )}
            {user?.role === UserRole.administrateur && (
              <Button type="submit" mt={16}>
                {isPlanning
                  ? form.values.redactorId
                    ? "Ajouter au planning"
                    : "Modifier"
                  : "Ajouter"}
              </Button>
            )}
          </Center>
        </form>
      </Modal>
    </>
  );
};

export default TaskForm;
