/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionIcon, Button, Title, Textarea } from "@mantine/core";
import CustomModal from "./secondary/CustomModal";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { IconClose } from "./IconType";
import GlowingIcon from "./secondary/GlowingIcon";
import { useApi } from "../useApi";
import { AxiosInstance } from "axios";
import { useParams } from "react-router-dom";
// import socket from "../socket";
import { showNotification } from "@mantine/notifications";
import { IconX } from "@tabler/icons-react";
import { UserDto, UserRole } from "../dto/user";
import { mailer } from "../apiRoutes";
import { MailjetTemplate } from "../dto/templates";

interface FileWithPreview extends File {
  preview: string;
  uploadPart: string; // new property to identify the part of upload
  uploadPercentage: number;
}

const FileUpload = ({
  files,
  setFiles,
  uploadPart,
  loading,
}: {
  files: FileWithPreview[];
  setFiles: React.Dispatch<React.SetStateAction<FileWithPreview[]>>;
  uploadPart: string; // new prop to identify the part of upload
  loading: boolean;
}) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    for (const file of acceptedFiles) {
      if (file.size > 2147483600) {
        showNotification({
          message: "Taille maximale de fichier dépassée (2Go)",
          icon: <IconX size="1.1rem" />,
          color: "red",
        });
        return;
      }
    }
    setFiles((prevFiles) => {
      // Remove duplicates from acceptedFiles
      const newFiles = acceptedFiles.filter(
        (acceptedFile) =>
          !prevFiles.some(
            (prevFile) =>
              prevFile.name === acceptedFile.name &&
              prevFile.size === acceptedFile.size &&
              prevFile.lastModified === acceptedFile.lastModified
          )
      );
      return [
        ...prevFiles,
        ...newFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            uploadPart, // mark the file with the part of upload
            uploadPercentage: 0,
          })
        ),
      ];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeFile = (index: number) => {
    const filteredFiles = files.filter(
      (file) => file.uploadPart === uploadPart
    );
    const fileToRemove = filteredFiles[index];
    const indexInOriginalArray = files.indexOf(fileToRemove);
    setFiles(files.filter((_, i) => i !== indexInOriginalArray));
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <>
      {!loading && (
        <div
          {...getRootProps()}
          style={{
            padding: "20px",
            border: "1px solid #2E6DA4",
            borderRadius: 12,
            backgroundColor: "#F5FBFF",
            cursor: "pointer",
          }}
        >
          <input {...getInputProps()} />
          <div style={{ display: "flex" }}>
            <GlowingIcon icon="upload" />
            <div>
              <p
                style={{
                  margin: 0,
                  fontWeight: 700,
                  color: "#2C3F53",
                  marginTop: 5,
                }}
              >
                Cliquez pour ajouter, ou glissez et déposez
              </p>
              <p style={{ margin: 0, color: "#656565" }}>
                MP3, MP4, MP4A, WMA, Word, PDF, JPG
              </p>
            </div>
          </div>
        </div>
      )}
      {files
        .filter((file) => file.uploadPart === uploadPart)
        .map((file, index) => (
          <>
            <div key={index} style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  margin: 0,
                }}
              >
                {file.name}{" "}
              </p>
              <p
                style={{
                  whiteSpace: "nowrap",
                  margin: 0,
                }}
              >
                {" "}
                {file.uploadPercentage > 0 && `(${file.uploadPercentage}%)`}
              </p>
              <ActionIcon
                style={{ marginLeft: 10 }}
                onClick={() => {
                  removeFile(index);
                }}
              >
                {IconClose}
              </ActionIcon>
            </div>
            {file.size > 536870900 && file.uploadPercentage === 100 && (
              <p>
                Traitement en cours d'un fichier volumineux, veuillez patienter
                maximum 1mn.
              </p>
            )}
          </>
        ))}
    </>
  );
};

const UploadModal = ({
  opened,
  setOpened,
  refresh,
  user,
}: {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  refresh: () => Promise<void>;
  user: UserDto | null;
}) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [comment, setComment] = useState<string>("");
  const { folderId, username } = useParams<{
    folderId?: string;
    username?: string;
  }>();
  const api = useApi();

  useEffect(() => {
    // Listen to 'progress' event for all file uploads
    // socket.on(
    //   "progress",
    //   ({ filename, progress }: { filename: string; progress: number }) => {
    //     setFiles((currentFiles) => {
    //       const fileIndex = currentFiles.findIndex(
    //         (file) => file.name === filename
    //       );
    //       if (fileIndex !== -1) {
    //         const newFiles = [...currentFiles];
    //         newFiles[fileIndex].uploadPercentage = progress;
    //         return newFiles;
    //       }
    //       return currentFiles;
    //     });
    //   }
    // );
    // There's no need to close the socket connection when the component unmounts,
    // because it's now managed globally
  }, []);

  const startUpload = async () => {
    if (files.length === 0) return;
    setLoading(true);
    for (let i = 0; i < files.length; i++) {
      // socket.emit("join", files[i].name);
      await uploadFile(api, files[i], folderId);
    }
    if (user?.role === UserRole.client) {
      mailer(api, {
        to: "notifaudio@gmail.com",
        template: MailjetTemplate.CUSTOMER_UPLOADED_INTERNAL,
        variables: {
          name: user?.username || "",
          files: files.map((f) => f.name).join(", "),
          comment: comment || "",
        },
      });
      mailer(api, {
        to:
          (user?.secoundEmail ?? "").length > 1
            ? [user?.email ?? "", user?.secoundEmail ?? ""]
            : [user?.email ?? ""],
        template: MailjetTemplate.CUSTOMER_UPLOADED_TO_CUSTOMER,
        variables: {
          name: user?.username || "",
          files: files.map((f) => f.name).join(", "),
        },
      });
    }
    setOpenedAndCleanRefresh();
  };

  const uploadFile = async (
    api: AxiosInstance,
    file: FileWithPreview,
    folderId?: string
  ) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("folderId", folderId || "");
    formData.append("username", username || "");

    await api.post("/googledrive/upload", formData, {
      headers: {
        "content-type": "multipart/form-data",
        "X-Custom-Filename": encodeURIComponent(file.name),
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total!
        );
        setFiles((currentFiles) => {
          const fileIndex = currentFiles.findIndex(
            (fileItem) => fileItem.name === file.name
          );
          if (fileIndex !== -1) {
            const newFiles = [...currentFiles];
            newFiles[fileIndex].uploadPercentage = percentCompleted;
            return newFiles;
          }
          return currentFiles;
        });
      },
    });
  };

  const setOpenedAndCleanRefresh = async () => {
    await refresh();
    setOpened(false);
    setComment("");
    setTimeout(() => {
      setFiles([]);
      setLoading(false);
    }, 600);
  };

  const setOpenedAndClean = async () => {
    setOpened(false);
    setFiles([]);
    setLoading(false);
    setComment("");
  };

  const list =
    user?.role === "client"
      ? [
          "Ordre du jour de la réunion",
          "Liste des participants",
          "Vos enregistrements",
        ]
      : ["Fichiers à transmettre"];

  return (
    <CustomModal
      opened={opened}
      setOpened={setOpenedAndClean}
      loading={loading}
    >
      <Title order={3} mt={10} mb={10} style={{ fontSize: 20 }} color="#333333">
        Transmettre mes fichiers
      </Title>
      {list.map((text, index) => (
        <div key={index}>
          {!loading ? (
            <p style={{ color: "#344054", fontWeight: 500, marginBottom: 6 }}>
              {text}
            </p>
          ) : null}
          <FileUpload
            files={files}
            setFiles={setFiles}
            uploadPart={`part${index}`}
            loading={loading}
          />
        </div>
      ))}

      {!loading && user?.role === UserRole.client && (
        <div style={{ marginTop: "1.5em" }}>
          <p style={{ color: "#344054", fontWeight: 500, marginBottom: 6 }}>
            Commentaire (optionnel)
          </p>
          <Textarea
            placeholder="Ajoutez vos commentaires concernant ces fichiers"
            value={comment}
            onChange={(e) => setComment(e.currentTarget.value)}
            minRows={3}
            style={{ width: "100%" }}
          />
        </div>
      )}

      <div
        style={{
          marginTop: "2em",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          style={{ marginRight: "1em", flex: 1, color: "#333333" }}
          onClick={setOpenedAndClean}
          variant="default"
        >
          Annuler
        </Button>
        <Button style={{ flex: 1 }} color="primary" onClick={startUpload}>
          Envoyer
        </Button>
      </div>
    </CustomModal>
  );
};
export default UploadModal;
