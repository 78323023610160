import { flexRender, HeaderGroup, RowData, Table } from "@tanstack/react-table";
import React from "react";
import Filter from "./Filter";
import {
  IconArrowDown,
  IconArrowsUpDown,
  IconArrowUp,
} from "@tabler/icons-react";
import { LoadingOverlay, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";

function getTableHeaderGroups<T extends RowData>(
  table: Table<T>
): [HeaderGroup<T>[], HeaderGroup<T>[]] {
  return [table.getHeaderGroups(), table.getFooterGroups()];
}

type Props<T extends RowData> = {
  table: Table<T>;
  loading: boolean;
  columns: any[];
  redirect: string | undefined;
};

export function CustomTable<T extends RowData>({
  table,
  loading,
  columns,
  redirect,
}: Props<T>) {
  const [headerGroups] = getTableHeaderGroups(table);
  const navigation = useNavigate();

  return (
    <table className="tanstack">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                className="relative"
                key={header.id}
                style={{
                  width: header.getSize(),
                  padding:
                    window.location.pathname === "/Campaign" ? 10 : undefined,
                }}
                colSpan={header.colSpan}
              >
                {header.isPlaceholder ? null : (
                  <>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}{" "}
                      {header.column.getCanSort() ? (
                        <div
                          onClick={header.column.getToggleSortingHandler()}
                          className="cursor-pointer select-none"
                          style={{ marginLeft: 4, marginTop: 2 }}
                        >
                          {{
                            asc: <IconArrowUp size={14} />,
                            desc: <IconArrowDown size={14} />,
                          }[header.column.getIsSorted() as string] ?? (
                            <IconArrowsUpDown size={14} />
                          )}
                        </div>
                      ) : null}
                    </div>
                    {header.column.getCanFilter() ? (
                      <div>
                        <Filter column={header.column} table={table} />
                      </div>
                    ) : null}
                  </>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td
              colSpan={columns.length}
              style={{
                textAlign: "center",
                padding: "40px 0",
                position: "relative",
              }}
            >
              <Title order={2}>{"\u00A0"}</Title>
              <LoadingOverlay
                visible={true}
                style={{
                  borderBottomLeftRadius: 12,
                  borderBottomRightRadius: 12,
                }}
              />
            </td>
          </tr>
        ) : (
          <>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                style={{
                  cursor:
                    window.location.pathname.includes("/action/") ||
                    redirect === "isTasks"
                      ? undefined
                      : "pointer",
                }}
                onClick={() => {
                  if (window.location.pathname.includes("/action/")) return;
                  let url;
                  console.log("redirect", redirect, row.original);
                  switch (redirect) {
                    case "isTasks":
                      // console.log(111, row);
                      // const deleteEvent = new CustomEvent("planTask", {
                      //   // @ts-ignore
                      //   detail: { taskId: row.original.id },
                      // });
                      // window.dispatchEvent(deleteEvent);
                      return;
                    case "user":
                      // @ts-ignore
                      url = `/users/${row.original.username}`;
                      break;
                    case undefined:
                    case "":
                      url =
                        window.location.pathname +
                        // @ts-ignore
                        `/action/${row.original.id}/view`;
                      break;
                    case "contract":
                      url =
                        window.location.pathname +
                        // @ts-ignore
                        `/action/${row.original.id}/view` +
                        window.location.search;
                      break;
                    case "history":
                      // @ts-ignore
                      url = `/Campaign/${row.original.campaignId}/Mission/${row.original.missionId}/Activity/action/${row.original.id}/view`;
                      break;
                    case "Notifications":
                      // @ts-ignore
                      url = row.original.redirectUrl;
                      break;
                    default:
                      url =
                        window.location.pathname +
                        // @ts-ignore
                        `/${row.original.id}/${redirect}`;
                  }
                  console.log("url", url);
                  navigation(url);
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    style={{
                      width: cell.column.getSize(),
                      padding:
                        window.location.pathname === "/Campaign"
                          ? 10
                          : undefined,
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
            {table.getRowModel().rows.length === 0 && (
              <tr>
                <td
                  colSpan={columns.length}
                  style={{
                    textAlign: "center",
                    padding: "40px 0",
                  }}
                >
                  <Title order={2}>Pas de résultats</Title>
                </td>
              </tr>
            )}
          </>
        )}
      </tbody>
    </table>
  );
}

export default CustomTable;
