import React, { useState, useEffect } from "react";
import { Modal, Select, Button, Stack } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { format } from "date-fns";
import { useApi } from "../useApi";
import { notifications } from "@mantine/notifications";
import { translateSpecialDayType } from "../utils";

const InOfficeDayModal = ({
  opened,
  close,
  redactors,
  onSuccess,
  initialType = "withoutpresence",
}: {
  opened: boolean;
  close: () => void;
  redactors: { label: string; value: string }[];
  onSuccess: () => void;
  initialType?: "withoutpresence" | "coworking" | "office";
}) => {
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedType, setSelectedType] = useState<string>(initialType);
  const [loading, setLoading] = useState(false);
  const api = useApi();

  useEffect(() => {
    if (opened) {
      setSelectedUser(null);
      setSelectedDate(null);
      setSelectedType(initialType);
    }
  }, [opened, initialType]);

  const handleSubmit = async () => {
    if (!selectedUser || !selectedDate) return;

    setLoading(true);
    try {
      await api.post("/inofficedates", {
        userId: parseInt(selectedUser),
        date: format(selectedDate, "yyyy-MM-dd"),
        type: selectedType,
      });
      onSuccess();
      close();
      setSelectedUser(null);
      setSelectedDate(null);
      setSelectedType("withoutpresence");
    } catch (error: any) {
      notifications.show({
        color: "red",
        title: "Erreur",
        message: error.response?.data?.message || "Une erreur est survenue",
      });
    }
    setLoading(false);
  };

  return (
    <Modal
      opened={opened}
      onClose={close}
      title={"Ajouter une " + translateSpecialDayType(selectedType)}
    >
      <Select
        label="Rédacteur"
        placeholder="Sélectionner un rédacteur"
        data={redactors}
        value={selectedUser}
        onChange={setSelectedUser}
        searchable
        mb={20}
      />
      <Stack align="center" spacing="md">
        <DatePicker
          placeholder="Sélectionner une date"
          value={selectedDate}
          onChange={setSelectedDate}
          excludeDate={(date) => date.getDay() === 0 || date.getDay() === 6}
          mb={20}
        />
        <Button
          onClick={handleSubmit}
          loading={loading}
          disabled={!selectedUser || !selectedDate}
        >
          Confirmer
        </Button>
      </Stack>
    </Modal>
  );
};

export default InOfficeDayModal;
