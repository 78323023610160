import { Modal, Button, Group, Text } from "@mantine/core";
import { useApi } from "../useApi";
import { notifications } from "@mantine/notifications";
import { translateSpecialDayType } from "../utils";
import { SpecialDayType } from "../dto/user";

interface DeleteInOfficeDayModalProps {
  opened: boolean;
  onClose: () => void;
  date: string;
  userId: string;
  onSuccess: () => void;
  type: SpecialDayType;
}

export const DeleteInOfficeDayModal = ({
  opened,
  onClose,
  date,
  userId,
  onSuccess,
  type,
}: DeleteInOfficeDayModalProps) => {
  const api = useApi();

  const handleDelete = async () => {
    try {
      await api.delete(`/inofficedates/${userId}/${date}`);
      notifications.show({
        color: "green",
        title: "Succès",
        message: `La ${translateSpecialDayType(
          type
        ).toLocaleLowerCase()} a été supprimée`,
      });
      onSuccess();
      onClose();
    } catch (error: any) {
      notifications.show({
        color: "red",
        title: "Erreur",
        message: error.response?.data?.message || "Une erreur est survenue",
      });
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        "Supprimer la " + translateSpecialDayType(type).toLocaleLowerCase()
      }
    >
      <Text size="sm" mb="lg">
        Êtes-vous sûr de vouloir supprimer cette{" "}
        {translateSpecialDayType(type).toLocaleLowerCase()} ?
      </Text>
      <Group position="right">
        <Button variant="outline" onClick={onClose}>
          Annuler
        </Button>
        <Button color="red" onClick={handleDelete}>
          Supprimer
        </Button>
      </Group>
    </Modal>
  );
};
