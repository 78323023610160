/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { MantineProvider } from "@mantine/core";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import { getMe } from "./apiRoutes";
import { UserDto, UserRole } from "./dto/user";
import { UserContext } from "./UserContext";
import { useApi } from "./useApi";
import { Notifications } from "@mantine/notifications";
import Users from "./pages/Users";
import NewUser from "./pages/NewUser";
import UserProfile from "./pages/UserProfile";
import MemberCalendar from "./pages/MemberCalendar";
import Tasks from "./pages/Tasks";
import Planning from "./pages/Planning";
import UsersPlanning from "./pages/UsersPlanning";
import Statistics from "./pages/Statistics";
import { TasksProvider } from "./contexts/TasksContext";

function App() {
  const { setUser, user } = useContext(UserContext);
  const api = useApi();
  const [isLoggedIn, setIsLoggedIn] = useState<Boolean | undefined>(undefined);

  useEffect(() => {
    const fetchUser = async () => {
      const user = JSON.parse(localStorage.getItem("user") as string);
      if (!user || !user.accessToken || !user.expiresAt) {
        setUser(null);
        setIsLoggedIn(false);
        return;
      }

      const isUserLoggedIn = Math.floor(Date.now() / 1000) <= user.expiresAt;
      setIsLoggedIn(isUserLoggedIn);
      if (!isUserLoggedIn) {
        setUser(null);
        return;
      }

      try {
        const user: UserDto = await getMe(api);
        setUser(user);
      } catch (error) {
        setUser(null);
      }
    };

    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoggedIn === undefined) return null;

  return (
    <TasksProvider>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          colorScheme: "light",
          colors: {
            blue: [
              "#2E6DA4",
              "#2E6DA4",
              "#2E6DA4",
              "#2E6DA4",
              "#2E6DA4",
              "#2E6DA4",
              "#2E6DA4",
              "#2E6DA4",
              "#2E6DA4",
              "#2E6DA4",
            ],
          },
          headings: {
            fontFamily: "Roboto, sans-serif",
            sizes: {
              h1: { fontSize: "20px" },
            },
          },
          radius: {
            sm: "8px",
            s4: "4px",
          },
          components: {
            Button: {
              styles: {
                root: {
                  height: 44,
                },
              },
            },
          },
        }}
      >
        <Notifications />
        <Router>
          <Routes>
            {isLoggedIn ? (
              <>
                {user?.role === UserRole.client && (
                  <>
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route path="/" element={<HomePage />} />
                    <Route path="/folder/:folderId" element={<HomePage />} />
                  </>
                )}
                {user?.role === UserRole.membre && (
                  <>
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route path="/" element={<MemberCalendar />} />
                  </>
                )}
                {user?.role === UserRole.administrateur && (
                  <>
                    <Route path="/statistiques" element={<Statistics />} />
                    <Route path="/clients" element={<UsersPlanning />} />
                    <Route path="/redacteurs" element={<UsersPlanning />} />
                    <Route path="/newuser" element={<NewUser />} />
                    <Route path="/enregistrements" element={<Tasks />} />
                    <Route path="/reunions" element={<Tasks />} />
                    <Route path="/users/:username" element={<UserProfile />} />
                    <Route
                      path="/users/:username/folder/:folderId"
                      element={<UserProfile />}
                    />
                    <Route path="/users" element={<Users />} />
                    <Route path="/planning" element={<Planning />} />
                  </>
                )}
                {user?.role === UserRole.observer && (
                  <Route path="/" element={<Planning />} />
                )}
                {user?.role === UserRole.administrateur &&
                  !user.isAdminPlanning && (
                    <>
                      <Route path="*" element={<Navigate to="/users" />} />
                    </>
                  )}
                {user?.role === UserRole.administrateur &&
                  user.isAdminPlanning && (
                    <>
                      <Route path="*" element={<Navigate to="/" />} />
                      <Route path="/" element={<Planning />} />
                    </>
                  )}
                <Route path="/login" element={<Navigate to="/" />} />
              </>
            ) : (
              <>
                <Route path="*" element={<Navigate to="/login" />} />
                <Route
                  path="/login"
                  element={<LoginPage onLogin={() => setIsLoggedIn(true)} />}
                />
              </>
            )}
          </Routes>
        </Router>
      </MantineProvider>
    </TasksProvider>
  );
}

export default App;
