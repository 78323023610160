import React, { useContext, useEffect, useState } from "react";
import { UserDto, UserRole } from "../dto/user";
import {
  Switch,
  Select,
  TextInput,
  Button,
  LoadingOverlay,
  Checkbox,
  NumberInput,
} from "@mantine/core";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useApi } from "../useApi";
import { deleteUser, mailer, register } from "../apiRoutes";
import { showNotification } from "@mantine/notifications";
import { IconX } from "@tabler/icons-react";
import DeleteModal from "./secondary/DeleteModal";
import { MailjetTemplate } from "../dto/templates";
import { useForm } from "@mantine/form";
import DynamicMultiSelect from "./DynamicMultiSelect";
import { UserContext } from "../UserContext";
import { processingLevel } from "../dto/processingLevel";

const UserForm = ({ user, profile }: { user?: UserDto; profile?: boolean }) => {
  const { user: appUser } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const api = useApi();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loadingDeleteModal, setLoadingDeleteModal] = useState(false);
  const [searchParams] = useSearchParams();
  const initialRole =
    searchParams.get("role") === "redactor" ? UserRole.membre : UserRole.client;

  const form = useForm<{
    code: string;
    phoneNumber: string;
    comment: string;
    processingLevel: string;
    orderNumber?: number;
    averageMeetingDuration?: number;
    email: string;
    secoundEmail: string;
    username: string;
    password: string;
    passwordConfirmation: string;
    role: UserRole;
    activated: boolean;
    sendInfoByEmail: boolean;
    places: string;
  }>({
    initialValues: {
      code: "",
      phoneNumber: "",
      comment: "",
      processingLevel: "",
      orderNumber: undefined,
      averageMeetingDuration: undefined,
      email: "",
      secoundEmail: "",
      username: "",
      password: "",
      passwordConfirmation: "",
      role: initialRole,
      activated: !window.location.href.includes("customer"),
      sendInfoByEmail: false,
      places: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Email non valide"),
      password: (value) =>
        (value.length < 8 || !/\d/.test(value) || !/[a-zA-Z]/.test(value)) &&
        value.length > 0
          ? "Le mot de passe doit comporter 8 caractères dont une lettre et un chiffre"
          : null,
      passwordConfirmation: (value, values) =>
        value !== values.password && !profile
          ? "Les mots de passe ne correspondent pas"
          : null,
    },
  });

  useEffect(() => {
    if (user) {
      form.setValues({
        code: user.code || "",
        phoneNumber: user.phoneNumber || "",
        comment: user.comment || "",
        processingLevel: user.processingLevel || "",
        orderNumber: user.orderNumber ?? undefined,
        averageMeetingDuration: user.averageMeetingDuration,
        email: user.email || "",
        secoundEmail: user.secoundEmail || "",
        username: user.username || "",
        role: user.role || UserRole.client,
        activated: user.activated !== undefined ? user.activated : true,
        places: user.places || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleDelete = async () => {
    try {
      setLoadingDeleteModal(true);
      await deleteUser(api, user?.id || 0);
      navigate(
        appUser?.isAdminPlanning
          ? form.values.role === UserRole.client
            ? "/clients"
            : "/redacteurs"
          : "/users"
      );
      setOpenDeleteModal(false);
    } catch (e) {
      console.log(e);
    }
    setLoadingDeleteModal(false);
  };

  const handleSubmit = async () => {
    const { password, passwordConfirmation, ...userData } = form.values;

    try {
      setLoading(true);
      const ret = await register(api, {
        ...userData,
        password,
        edit: profile,
        id: user?.id || 0,
      });
      if (ret === "username_taken") {
        showNotification({
          message: "Login déjà pris",
          icon: <IconX size="1.1rem" />,
          color: "red",
        });
        // } else if (ret === "email_taken") {
        //   showNotification({
        //     message: "Email déjà pris",
        //     icon: <IconX size="1.1rem" />,
        //     color: "red",
        //   });
      } else {
        navigate(
          appUser?.isAdminPlanning
            ? form.values.role === UserRole.client
              ? "/clients"
              : "/redacteurs"
            : "/users"
        );
        if (form.values.sendInfoByEmail)
          await mailer(api, {
            username: form.values.username,
            template: MailjetTemplate.REGISTER_NEW_USER,
            variables: {
              name: form.values.username,
              password,
            },
          });
      }
    } catch (error: any) {
      console.error("Failed to create or update user", error);
    }
    setLoading(false);
  };

  return (
    <form
      onSubmit={form.onSubmit(handleSubmit)}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 20,
        position: "relative",
      }}
    >
      {/* {JSON.stringify(form.values)} */}
      <TextInput
        label="Code utilisateur"
        placeholder="Entrez un code utilisateur"
        {...form.getInputProps("code")}
        onChange={(e) => {
          const newValue = e.target.value;
          form.setFieldValue("code", newValue);
          if (
            (newValue.length > form.values.code.length &&
              form.values.username.toLowerCase() ===
                form.values.code.toLowerCase()) ||
            form.values.username.length === 0
          ) {
            form.setFieldValue("username", newValue.toLowerCase());
          }
        }}
        styles={{ input: { height: 44 } }}
        required
      />
      <TextInput
        label="Login"
        placeholder="Entrez un login"
        {...form.getInputProps("username")}
        onChange={(e) => {
          const newValue = e.target.value;
          form.setFieldValue("username", newValue);
          if (
            (newValue.length > form.values.username.length &&
              form.values.code.toUpperCase() ===
                form.values.username.toUpperCase()) ||
            form.values.code.length === 0
          ) {
            form.setFieldValue("code", newValue.toUpperCase());
          }
        }}
        styles={{ input: { height: 44 } }}
        required
      />
      <Select
        styles={{ input: { height: 44 } }}
        label="Role"
        required
        data={[
          { value: UserRole.client, label: "Client" },
          { value: UserRole.administrateur, label: "Administrateur" },
          { value: UserRole.membre, label: "Rédacteur" },
          { value: UserRole.observer, label: "Observateur" },
        ]}
        {...form.getInputProps("role")}
        disabled={profile}
      />
      <DynamicMultiSelect form={form} />
      <div style={{ display: "flex", width: "100%", gap: 20 }}>
        <TextInput
          label="Téléphone"
          placeholder="Entrez un numéro de téléphone"
          {...form.getInputProps("phoneNumber")}
          styles={{ input: { height: 44 } }}
          w={form.values.role !== UserRole.administrateur ? "50%" : "100%"}
        />
        {form.values.role === UserRole.client && (
          <Select
            label="Niveau de traitement"
            placeholder="Choisissez un niveau de traitement"
            styles={{ input: { height: 44 } }}
            data={processingLevel}
            {...form.getInputProps("processingLevel")}
            searchable
            clearable
            w="50%"
          />
        )}
        {form.values.role === UserRole.membre && (
          <NumberInput
            label="Numéro de classement"
            placeholder="Choisissez chiffre de classement, ordre croissant"
            styles={{ input: { height: 44 } }}
            {...form.getInputProps("orderNumber")}
            w="50%"
            min={1}
            max={1000000}
          />
        )}
      </div>
      <div style={{ display: "flex", width: "100%", gap: 20 }}>
        <TextInput
          label="Commentaire"
          placeholder="Entre un commentaire"
          {...form.getInputProps("comment")}
          styles={{ input: { height: 44 } }}
          w={form.values.role === UserRole.client ? "50%" : "100%"}
        />
        {form.values.role === UserRole.client && (
          <NumberInput
            label="Durée moyenne des réunions (en heures)"
            placeholder="Entrer une durée moyenne des réunions"
            {...form.getInputProps("averageMeetingDuration")}
            styles={{ input: { height: 44 } }}
            max={14}
            min={0}
            step={0.5}
            precision={1}
            w="50%"
            value={form.values.averageMeetingDuration ?? undefined}
          />
        )}
      </div>
      <div style={{ display: "flex", width: "100%", gap: 20 }}>
        <TextInput
          label="Email"
          placeholder="Entrez un email"
          {...form.getInputProps("email")}
          styles={{ input: { height: 44 } }}
          required
          w="50%"
        />
        <TextInput
          label="Second email (optionnel)"
          placeholder="Entrez un second email"
          {...form.getInputProps("secoundEmail")}
          styles={{ input: { height: 44 } }}
          w="50%"
        />
      </div>
      <div style={{ display: "flex", width: "100%", gap: 20 }}>
        <TextInput
          label={!profile ? "Mot de passe" : "Nouveau mot de passe"}
          placeholder={
            !profile
              ? "Entrez votre mot de passe"
              : "Entrez un nouveau mot de passe si vous souhaitez le changer"
          }
          {...form.getInputProps("password")}
          styles={{ input: { height: 44 } }}
          required={!profile}
          description="Doit comporter 8 caractères dont une lettre et un chiffre"
          w="50%"
        />
        {!profile && (
          <TextInput
            label="Confimation du mot de passe"
            description="Doit comporter 8 caractères dont une lettre et un chiffre"
            placeholder="Entrez votre mot de passe"
            {...form.getInputProps("passwordConfirmation")}
            styles={{ input: { height: 44 } }}
            required
            w="50%"
          />
        )}
      </div>
      {(!profile || form.values.password.length > 0) && (
        <Checkbox
          label="Envoyer les informations par mail"
          radius="s4"
          {...form.getInputProps("sendInfoByEmail", { type: "checkbox" })}
        />
      )}
      <Switch
        label={
          form.values.role === UserRole.membre
            ? "Compte activé"
            : "Activer le compte pour la dépose de documents"
        }
        size="md"
        {...form.getInputProps("activated", { type: "checkbox" })}
      />
      {profile && (
        <div>
          <Button onClick={() => setOpenDeleteModal(true)} color="red">
            Supprimer
          </Button>
        </div>
      )}
      <div
        style={{
          width: "100%",
          borderTop: "1px solid #EAECF0",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          style={{ marginRight: "1em", color: "#333333" }}
          onClick={() => navigate("/users")}
          variant="default"
        >
          Annuler
        </Button>
        <Button type="submit" disabled={loading}>
          Enregistrer
        </Button>
      </div>

      {loading && (
        <LoadingOverlay
          visible
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
          }}
        />
      )}

      <DeleteModal
        handleDelete={handleDelete}
        opened={openDeleteModal}
        setOpened={setOpenDeleteModal}
        loading={loadingDeleteModal}
        profile
      />
    </form>
  );
};

export default UserForm;
