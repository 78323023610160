import Highlight from "@tiptap/extension-highlight";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { RichTextEditor } from "@mantine/tiptap";
import { useEffect } from "react";
import { Box, Text, createStyles } from "@mantine/core";
import TextStyle from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";

const useStyles = createStyles(() => ({
  root: {
    "& .mantine-RichTextEditor-control": {
      "&[data-active]": {
        color: "white",
      },
    },
  },
}));

interface RichTextProps {
  label?: string;
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
}

function RichText({ value = "", onChange, label, placeholder }: RichTextProps) {
  const { classes } = useStyles();
  const editor = useEditor({
    extensions: [StarterKit, Underline, Highlight, TextStyle, Color],
    content: value,
    onUpdate: ({ editor }) => {
      onChange?.(editor.getHTML());
    },
  });

  useEffect(() => {
    if (editor && value !== editor.getHTML()) {
      editor.commands.setContent(value);
    }
  }, [value, editor]);

  return (
    <Box mt={10} w="100%">
      {label && (
        <Text size="sm" fw={500} mb={3}>
          {label}
        </Text>
      )}
      <RichTextEditor editor={editor} className={classes.root}>
        <RichTextEditor.Toolbar sticky stickyOffset={60}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.ColorPicker
              colors={[
                "#F03E3E",
                "#F76707",
                "#FF922B",
                "#F59F00",
                "#FFD43B",
                "#20C997",
                "#37B24D",
                "#2E6DA4",
                "#7950F2",
                "#AE3EC9",
                "#D6336C",
                "#868E96",
                "#495057",
                "#343A40",
              ]}
            />
            <RichTextEditor.ClearFormatting />
            <RichTextEditor.Highlight />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content placeholder={placeholder} />
      </RichTextEditor>
    </Box>
  );
}

export default RichText;
