export const TimeSelectOptions = [
  { value: "00:00:00.000Z", label: "0:00" },
  { value: "00:30:00.000Z", label: "0:30" },
  { value: "01:00:00.000Z", label: "1:00" },
  { value: "01:30:00.000Z", label: "1:30" },
  { value: "02:00:00.000Z", label: "2:00" },
  { value: "02:30:00.000Z", label: "2:30" },
  { value: "03:00:00.000Z", label: "3:00" },
  { value: "03:30:00.000Z", label: "3:30" },
  { value: "04:00:00.000Z", label: "4:00" },
  { value: "04:30:00.000Z", label: "4:30" },
  { value: "05:00:00.000Z", label: "5:00" },
  { value: "05:30:00.000Z", label: "5:30" },
  { value: "06:00:00.000Z", label: "6:00" },
  { value: "06:30:00.000Z", label: "6:30" },
  { value: "07:00:00.000Z", label: "7:00" },
];

export const TaskDurationSelectOptions = [
  { value: "0.5", label: "0:30" },
  { value: "1", label: "1:00" },
  { value: "1.5", label: "1:30" },
  { value: "2", label: "2:00" },
  { value: "2.5", label: "2:30" },
  { value: "3", label: "3:00" },
  { value: "3.5", label: "3:30 - 0.5 jour" },
  { value: "4", label: "4:00" },
  { value: "4.5", label: "4:30" },
  { value: "5", label: "5:00" },
  { value: "5.5", label: "5:30" },
  { value: "6", label: "6:00" },
  { value: "6.5", label: "6:30" },
  { value: "7", label: "7:00 - 1 jour" },
  { value: "7.5", label: "7:30" },
  { value: "8", label: "8:00" },
  { value: "8.5", label: "8:30" },
  { value: "9", label: "9:00" },
  { value: "9.5", label: "9:30" },
  { value: "10", label: "10:00" },
  { value: "10.5", label: "10:30 - 1.5 jour" },
  { value: "11", label: "11:00" },
  { value: "11.5", label: "11:30" },
  { value: "12", label: "12:00" },
  { value: "12.5", label: "12:30" },
  { value: "13", label: "13:00" },
  { value: "13.5", label: "13:30" },
  { value: "14", label: "14:00 - 2 jours" },
  { value: "14.5", label: "14:30" },
  { value: "15", label: "15:00" },
  { value: "15.5", label: "15:30" },
  { value: "16", label: "16:00" },
  { value: "16.5", label: "16:30" },
  { value: "17", label: "17:00" },
  { value: "17.5", label: "17:30 - 2.5 jours" },
  { value: "18", label: "18:00" },
  { value: "18.5", label: "18:30" },
  { value: "19", label: "19:00" },
  { value: "19.5", label: "19:30" },
  { value: "20", label: "20:00" },
  { value: "20.5", label: "20:30" },
  { value: "21", label: "21:00 - 3 jours" },
  { value: "21.5", label: "21:30" },
  { value: "22", label: "22:00" },
  { value: "22.5", label: "22:30" },
  { value: "23", label: "23:00" },
  { value: "23.5", label: "23:30" },
  { value: "24", label: "24:00" },
  { value: "24.5", label: "24:30 - 3.5 jours" },
  { value: "25", label: "25:00" },
  { value: "25.5", label: "25:30" },
  { value: "26", label: "26:00" },
  { value: "26.5", label: "26:30" },
  { value: "27", label: "27:00" },
  { value: "27.5", label: "27:30" },
  { value: "28", label: "28:00 - 4 jours" },
  { value: "28.5", label: "28:30" },
  { value: "29", label: "29:00" },
  { value: "29.5", label: "29:30" },
  { value: "30", label: "30:00" },
  { value: "30.5", label: "30:30" },
  { value: "31", label: "31:00" },
  { value: "31.5", label: "31:30 - 4.5 jours" },
  { value: "32", label: "32:00" },
  { value: "32.5", label: "32:30" },
  { value: "33", label: "33:00" },
  { value: "33.5", label: "33:30" },
  { value: "34", label: "34:00" },
  { value: "34.5", label: "34:30" },
  { value: "35", label: "35:00 - 5 jours" },
  { value: "35.5", label: "35:30" },
  { value: "36", label: "36:00" },
  { value: "36.5", label: "36:30" },
  { value: "37", label: "37:00" },
  { value: "37.5", label: "37:30" },
  { value: "38", label: "38:00" },
  { value: "38.5", label: "38:30 - 5.5 jours" },
  { value: "39", label: "39:00" },
  { value: "39.5", label: "39:30" },
  { value: "40", label: "40:00" },
  { value: "40.5", label: "40:30" },
  { value: "41", label: "41:00" },
  { value: "41.5", label: "41:30" },
  { value: "42", label: "42:00 - 6 jours" },
  { value: "42.5", label: "42:30" },
  { value: "43", label: "43:00" },
  { value: "43.5", label: "43:30" },
  { value: "44", label: "44:00" },
  { value: "44.5", label: "44:30" },
  { value: "45", label: "45:00" },
  { value: "45.5", label: "45:30 - 6.5 jours" },
  { value: "46", label: "46:00" },
  { value: "46.5", label: "46:30" },
  { value: "47", label: "47:00" },
  { value: "47.5", label: "47:30" },
  { value: "48", label: "48:00" },
  { value: "48.5", label: "48:30" },
  { value: "49", label: "49:00 - 7 jours" },
];
