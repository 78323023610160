import { AxiosInstance } from "axios";
import { ChangePasswordDto, TaskDto, UserDto } from "./dto/user";
import { MailerDto } from "./dto/templates";
import { State } from "./dto/googledrive";

export const login = async (
  api: AxiosInstance,
  username: string,
  password: string
) => {
  const response = await api.post("/auth/login", { username, password });
  return response.data;
};

export const logout = async (api: AxiosInstance) => {
  const response = await api.post("/auth/logout");
  return response.data;
};

export const getMe = async (api: AxiosInstance) => {
  try {
    const response = await api.get("/auth/me");
    return response.data;
  } catch (error) {
    throw new Error("Unauthorized");
  }
};

export const getGoogleDriveHome = async (
  api: AxiosInstance,
  folderId?: string
) => {
  const endpoint = folderId ? `/googledrive/${folderId}` : "/googledrive/home";
  const response = await api.get(endpoint);
  return response.data;
};

export const getUserFolders = async (api: AxiosInstance, username: string) => {
  const response = await api.get(`/googledrive/user-folders/${username}`);
  return response.data?.files;
};

export const getUsers = async (api: AxiosInstance) => {
  const response = await api.get("/user");
  return response.data;
};
export const getUsersActivatedMembers = async (api: AxiosInstance) => {
  const response = await api.get("/user/activatedmembers");
  return response.data;
};
export const getUsersMembers = async (api: AxiosInstance) =>
  api.get("/user/members");
export const getUsersCustomers = async (api: AxiosInstance) =>
  api.get("/user/customers");

export const getUser = async (api: AxiosInstance, username: string) => {
  const response = await api.get(`/user/${username}`);
  return response.data;
};

export const getFolderDetails = async (
  api: AxiosInstance,
  folderId?: string
) => {
  console.log("getFolderDetails");
  const response = await api.get(`/googledrive/folderdetails/${folderId}`);
  return response.data;
};

export const postFolder = async (
  api: AxiosInstance,
  folderName: string,
  folderId?: string,
  username?: string
) => {
  console.log("postFolder");
  const response = await api.post("/googledrive/createfolder", {
    name: folderName,
    folderId: folderId,
    username,
  });
  return response.data;
};

export const deleteFile = async (api: AxiosInstance, fileId: string) => {
  const response = await api.delete(`/googledrive/${fileId}`);
  return response.data;
};

export const deleteUser = async (api: AxiosInstance, userId: number) => {
  const response = await api.delete(`/user/${userId}`);
  return response.data;
};

export const moveFile = async (
  api: AxiosInstance,
  fileId: string,
  folderId: string
) => {
  const response = await api.put(`/googledrive/${fileId}/move/${folderId}`);
  return response.data;
};

export const downloadFiles = async (api: AxiosInstance, fileIds: string[]) => {
  console.log("downloadFiles");
  const response = await api.post(
    "/googledrive/download",
    { fileIds },
    { responseType: "blob" }
  );
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;

  const contentDisposition = response.headers["content-disposition"];
  let fileName = "fichiers.zip"; // default name
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
    if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
  }

  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  return response.data;
};

export const postNewPassword = async (
  api: AxiosInstance,
  currentPassword: string,
  newPassword: string
) => {
  const response = await api.post("/auth/new-password", {
    currentPassword,
    newPassword,
  });
  return response.data;
};

export const register = async (api: AxiosInstance, userDto: UserDto) => {
  const response = await api.post("/auth/register", userDto);
  return response.data;
};

export const mailer = async (api: AxiosInstance, mailerDto: MailerDto) => {
  const response = await api.post("/mailer", mailerDto);
  return response.data;
};

export const mailerResetPassword = async (
  api: AxiosInstance,
  mailerDto: MailerDto
) => {
  const response = await api.post("/mailer/reset-password", mailerDto);
  return response.data;
};

export const resetPasswordToken = async (
  api: AxiosInstance,
  changePasswordDto: ChangePasswordDto
) => {
  const response = await api.post("/auth/forgot-password", changePasswordDto);
  return response.data;
};

export const validateFile = async (
  api: AxiosInstance,
  fileId: string,
  realState: State
) => {
  const realStateKey = Object.keys(State).find(
    (key) => State[key as keyof typeof State] === realState
  );
  const response = await api.post(
    `/googledrive/${fileId}/validate/${realStateKey}`
  );
  return response.data;
};

export const getTasks = (api: AxiosInstance, loadRecentTasks: boolean = true) =>
  api.get<TaskDto[]>(`/task?loadRecentTasks=${loadRecentTasks}`);
export const getTasksMember = async (api: AxiosInstance) =>
  api.get("/task/member");
export const postTasks = async (api: AxiosInstance, form: any) =>
  api.post("/task", form);
export const confirmTaskFinishedMember = async (
  api: AxiosInstance,
  id: number,
  timeAtTheCustomer?: string,
  duration?: string,
  commentRedactor?: string
) =>
  api.post(`/task/confirm-task-finished-member`, {
    id,
    timeAtTheCustomer,
    duration,
    commentRedactor,
  });
export const postDeliverBillDelete = async (
  api: AxiosInstance,
  taskId: number,
  type: "Facturer" | "Livrer" | "Supprimer"
) => api.post(`/task/deliver-bill-delete`, { type, taskId });

export const getTaskStatistics = async (api: AxiosInstance) =>
  api.get("/task/statistics");

export const getWorkingHoursStatistics = (api: AxiosInstance) =>
  api.get("/task/working-hours-statistics");
