export const processingLevel = [
  {
    value: "TRS",
    label: "TRS - Transcription mot à mot – 1 heure",
    maxTimePerDay: 1,
  },
  {
    value: "CRE",
    label: "CRE - Compte rendu exhaustif – 1h",
    maxTimePerDay: 1,
  },
  {
    value: "CRO",
    label: "CRO - Compte rendu optimisé – 1h30",
    maxTimePerDay: 1.5,
  },
  {
    value: "CRS",
    label: "CRS - Compte rendu synthétique – 2h15",
    maxTimePerDay: 2.25,
  },
  { value: "SYD", label: "SYD - Synthèse détaillée – 3h", maxTimePerDay: 3 },
  {
    value: "SYBD",
    label: "SYBD - Synthèse brève-détaillée – 3h30",
    maxTimePerDay: 3.5,
  },
  { value: "SYB", label: "SYB - Synthèse brève – 4h", maxTimePerDay: 4 },
  {
    value: "RD",
    label: "RD - Relevé de décisions – 1/3 de journée",
    fixedDuration: 2.5,
  },
  {
    value: "SYB + RD",
    label: "SYB + RD - Synthèse brève 4h + Relevé de décisions 1/3 de journée",
    maxTimePerDay: 4,
    fixedDuration: 2.5,
  },
  {
    value: "SYD + RD",
    label:
      "SYD + RD - Synthèse détaillée 3h + Relevé de décisions 1/3 de journée",
    maxTimePerDay: 3,
    fixedDuration: 2.5,
  },
  {
    value: "CRS + RD",
    label:
      "CRS + RD - Compte rendu synthétique 2h15 + Relevé de décisions 1/3 de journée",
    maxTimePerDay: 2.25,
    fixedDuration: 2.5,
  },
  {
    value: "CRO + RD",
    label:
      "CRO + RD - Compte rendu optimisé 1h30 + Relevé de décisions 1/3 de journée",
    maxTimePerDay: 1.5,
    fixedDuration: 2.5,
  },
  {
    value: "SYD + SYB",
    label: "SYD + SYB - Synthèse détaillée 3h + Synthèse brève 4h",
    maxTimePerDay: 7,
  },
];
