import moment, { Moment } from "moment"; // Ensure moment is imported at the top of your file
import { TaskType } from "./dto/user";
import { processingLevel } from "./dto/processingLevel";
type SizeUnit = "Ko" | "Mo" | "Go";

export function formatFileSize(sizeInBytes: number | undefined): string {
  if (!sizeInBytes) return "";
  let size: number = sizeInBytes; // size in bytes
  let unit: SizeUnit = "Ko";

  if (size >= 1000) {
    // size is greater than or equal to 1000 bytes, convert to Ko
    size /= 1024;
    unit = "Ko";
  }

  if (size >= 1000) {
    // size is greater than or equal to 1000 Ko, convert to Mo
    size /= 1024;
    unit = "Mo";
  }

  if (size >= 1000) {
    // size is greater than or equal to 1000 Mo, convert to Go
    size /= 1024;
    unit = "Go";
  }

  if (unit === "Go") {
    size = Math.round((size + Number.EPSILON) * 100) / 100; // round to 2 decimal places
  } else {
    size = Math.round(size); // round to nearest whole number
  }

  return size.toString() + " " + unit;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatDateToUTC(dateString?: Date) {
  if (!dateString) return "";

  // Use moment to handle the date and format it
  const date = moment.utc(dateString);
  const formattedDate = `${date.format("DD MMMM")}`; // DD for day, MMMM for full month name
  const formattedTime = `${date.format("HH:mm")}`; // HH:mm for hour and minutes in 24-hour format

  return `${formattedDate} ${formattedTime}`;
}

export function combineDateAndTime(date?: Date, time?: string) {
  if (date && time) {
    const localDate = new Date(date);
    const timezoneOffset = localDate.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(localDate.getTime() - timezoneOffset);
    const dateParts = adjustedDate.toISOString().split("T")[0].split("-");
    const timeParts = time.split(":");
    return new Date(
      Date.UTC(
        parseInt(dateParts[0]), // Year
        parseInt(dateParts[1]) - 1, // Month (0-indexed in JavaScript)
        parseInt(dateParts[2]), // Day
        parseInt(timeParts[0]), // Hour (assumed to be in UTC)
        parseInt(timeParts[1]), // Minute
        parseInt(timeParts[2]) // Second (assumed to be 0 if not present)
      )
    );
  }
  return undefined;
}

export function timeStringToHours(
  timeString: string | undefined
): number | undefined {
  if (!timeString) return undefined;
  const [hours, minutes] = timeString.split(":").map(Number);
  return hours + minutes / 60;
}

export function calculateExpectedTime(
  code: string,
  recordingHoursString?: string
): number {
  const recordingHours = timeStringToHours(recordingHoursString);
  const task = processingLevel.find((task) => task.value === code);
  if (!task || !recordingHours) return 0;

  const workdayHours = 7;

  // Calculate how much work can be done in one day
  let totalTimeNeeded = 0;
  if (task.maxTimePerDay) {
    totalTimeNeeded = (recordingHours * workdayHours) / task.maxTimePerDay;
  }
  return (
    (task?.fixedDuration ? task.fixedDuration : 0) +
    Math.round(totalTimeNeeded * 100) / 100
  );
}

export function ceilToNearestHalf(num: number): number {
  return Math.ceil(num * 2) / 2;
}

export function convertToClockTime(hours: number): string {
  const fullHours = Math.floor(hours);
  const minutes = (hours - fullHours) * 60;
  const hoursString = fullHours.toString().padStart(2, "0");
  const minutesString = Math.round(minutes).toString().padStart(2, "0");
  return `${hoursString}:${minutesString}`;
}

export const generateDays = (currentMonth: Moment) => {
  const days = [];
  const startOfMonth = currentMonth.clone().startOf("month");
  const endOfMonth = currentMonth.clone().endOf("month");
  const today = moment();

  if (currentMonth.isSame(today, "month")) {
    const twoWeeksFromNow = today.clone().add(14, "days");
    const endDate = moment.max(endOfMonth, twoWeeksFromNow);

    for (
      let day = startOfMonth;
      day.isSameOrBefore(endDate);
      day.add(1, "days")
    ) {
      if (day.day() !== 0 && day.day() !== 6) {
        days.push(day.clone());
      }
    }
  } else {
    for (
      let day = startOfMonth;
      day.isSameOrBefore(endOfMonth);
      day.add(1, "days")
    ) {
      if (day.day() !== 0 && day.day() !== 6) {
        days.push(day.clone());
      }
    }
  }

  return days;
};

export function isTaskTypeFixed(type?: TaskType): boolean {
  return [TaskType.meeting, TaskType.break, TaskType.otherFixed].includes(
    type as TaskType
  );
}

export function isTaskTypeOnlyPlanning(type?: TaskType): boolean {
  return [TaskType.break, TaskType.otherFixed, TaskType.otherFlexible].includes(
    type as TaskType
  );
}

export const translateTaskSubType = (subType?: string): string => {
  if (!subType) return "";

  const translations: { [key: string]: string } = {
    relectures: "Relectures",
    apv: "APV",
    v2: "V2",
    formation: "Formation",
    delegation: "Délégation",
  };

  return translations[subType] || subType;
};

export const translateSpecialDayType = (type?: string): string => {
  if (!type) return "";

  const translations: { [key: string]: string } = {
    withoutpresence: "Journée sans présence",
    coworking: "Journée de coworking",
    office: "Journée au bureau",
  };

  return translations[type] || type;
};

export const stripHtml = (html: string | undefined) => {
  if (!html) return "";
  const tmp = document.createElement("div");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};
