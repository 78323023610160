import { Box, Text, Title, Loader, Table, Tabs, Modal } from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { HeaderAction } from "../components/HeaderAction";
import { UserContext } from "../UserContext";
import { getTaskStatistics, getWorkingHoursStatistics } from "../apiRoutes";
import { useApi } from "../useApi";
import { format, parseISO } from "date-fns";
import { fr } from "date-fns/locale";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

interface StatisticItem {
  week: string;
  averageDays: number;
  totalTasks: number;
  totalRecordingWritingTasks: number;
  totalMeetingWritingTasks: number;
  totalRecordingReceived: number;
}

interface WorkingHoursItem {
  month: string;
  meeting: number;
  recordingWriting: number;
  meetingWriting: number;
  other: {
    total: number;
    relectures: number;
    apv: number;
    v2: number;
    formation: number;
    delegation: number;
    unspecified: number;
  };
}

ChartJS.register(ArcElement, Tooltip, Legend);

const Statistics = () => {
  const { user } = useContext(UserContext);
  const api = useApi();
  const [statistics, setStatistics] = useState<StatisticItem[]>([]);
  const [workingHours, setWorkingHours] = useState<WorkingHoursItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState<WorkingHoursItem | null>(
    null
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [statsResponse, hoursResponse] = await Promise.all([
          getTaskStatistics(api),
          getWorkingHoursStatistics(api),
        ]);
        setStatistics(statsResponse.data);
        setWorkingHours(hoursResponse.data);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [api]);

  const columns = [
    { key: "week", label: "Semaine de livraison" },
    { key: "averageDays", label: "Délai moyen (jours)" },
    { key: "totalTasks", label: "Nombre total de tâches" },
    { key: "totalRecordingWritingTasks", label: "Rédactions d'audios" },
    { key: "totalMeetingWritingTasks", label: "Rédactions de réunions" },
    { key: "totalRecordingReceived", label: "Audios reçus" },
  ];

  const formatWeek = (weekDate: string) => {
    const date = parseISO(weekDate);
    const formattedDate = format(date, "'S'II - dd/MM/yyyy", { locale: fr });
    return formattedDate;
  };

  const formatMonth = (monthStr: string) => {
    const [year, month] = monthStr.split("-");
    return format(new Date(parseInt(year), parseInt(month) - 1), "MMMM yyyy", {
      locale: fr,
    });
  };

  const getChartData = (item: WorkingHoursItem) => {
    const values = [
      item.meeting,
      item.recordingWriting,
      item.meetingWriting,
      item.other.relectures,
      item.other.apv,
      item.other.v2,
      item.other.formation,
      item.other.delegation,
      item.other.unspecified,
    ];

    return {
      labels: [
        "Réunions",
        "Rédaction d'audios",
        "Rédaction de réunions",
        "Relectures",
        "APV",
        "V2",
        "Formation",
        "Délégation",
        "Non spécifié",
      ],
      datasets: [
        {
          data: values,
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#9966FF",
            "#FF9F40",
            "#FF99CC",
            "#66FF99",
            "#99CCFF",
          ],
        },
      ],
    };
  };

  return (
    <Box bg={"#F9FAFB"} pb={1}>
      <HeaderAction planning={user?.isAdminPlanning} />
      <Box p={30} bg="#F9FAFB">
        <Title>Statistiques</Title>
        <Tabs defaultValue="delivery">
          <Tabs.List>
            <Tabs.Tab value="delivery">Délais de livraison</Tabs.Tab>
            <Tabs.Tab value="hours">Heures de travail</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="delivery" pt="xs">
            <Text>
              Temps moyen par semaine entre la réunion/dépose enregistrement et
              la livraison
            </Text>
            {loading ? (
              <Loader />
            ) : (
              <Table striped highlightOnHover>
                <thead>
                  <tr>
                    {columns.map((column) => (
                      <th key={column.key}>{column.label}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {statistics.map((item) => (
                    <tr key={item.week}>
                      <td>{formatWeek(item.week)}</td>
                      <td>{item.averageDays.toFixed(2)}</td>
                      <td>{item.totalTasks}</td>
                      <td>{item.totalRecordingWritingTasks}</td>
                      <td>{item.totalMeetingWritingTasks}</td>
                      <td>{item.totalRecordingReceived}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Tabs.Panel>

          <Tabs.Panel value="hours" pt="xs">
            <Text>
              Répartition des heures de travail par catégorie et par mois
            </Text>
            {loading ? (
              <Loader />
            ) : (
              <>
                <Table striped highlightOnHover>
                  <thead>
                    <tr>
                      <th>Mois</th>
                      <th>Réunions</th>
                      <th>Rédaction d'audios</th>
                      <th>Rédaction de réunions</th>
                      <th colSpan={7}>Autres</th>
                      <th>Total</th>
                    </tr>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th>Total</th>
                      <th>Relectures</th>
                      <th>APV</th>
                      <th>V2</th>
                      <th>Formation</th>
                      <th>Délégation</th>
                      <th>Non spécifié</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {workingHours
                      .sort((a, b) => b.month.localeCompare(a.month))
                      .map((item) => (
                        <tr
                          key={item.month}
                          style={{ cursor: "pointer" }}
                          onClick={() => setSelectedMonth(item)}
                        >
                          <td>{formatMonth(item.month)}</td>
                          <td>{item.meeting.toFixed(1)}h</td>
                          <td>{item.recordingWriting.toFixed(1)}h</td>
                          <td>{item.meetingWriting.toFixed(1)}h</td>
                          <td>{item.other.total.toFixed(1)}h</td>
                          <td>{item.other.relectures.toFixed(1)}h</td>
                          <td>{item.other.apv.toFixed(1)}h</td>
                          <td>{item.other.v2.toFixed(1)}h</td>
                          <td>{item.other.formation.toFixed(1)}h</td>
                          <td>{item.other.delegation.toFixed(1)}h</td>
                          <td>{item.other.unspecified.toFixed(1)}h</td>
                          <td>
                            {(
                              item.meeting +
                              item.recordingWriting +
                              item.meetingWriting +
                              item.other.total
                            ).toFixed(1)}
                            h
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>

                <Modal
                  opened={selectedMonth !== null}
                  onClose={() => setSelectedMonth(null)}
                  title={`Répartition des heures - ${
                    selectedMonth ? formatMonth(selectedMonth.month) : ""
                  }`}
                  size="lg"
                >
                  {selectedMonth && (
                    <Box style={{ height: 400 }}>
                      <Pie
                        data={getChartData(selectedMonth)}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          plugins: {
                            tooltip: {
                              callbacks: {
                                label: (context) => {
                                  const value = context.raw as number;
                                  const total = context.dataset.data.reduce(
                                    (a: number, b: number) => a + b,
                                    0
                                  );
                                  const percentage = (
                                    (value * 100) /
                                    total
                                  ).toFixed(1);
                                  return `${context.label}: ${value.toFixed(
                                    1
                                  )}h (${percentage}%)`;
                                },
                              },
                            },
                            legend: {
                              position: "right",
                            },
                          },
                        }}
                      />
                    </Box>
                  )}
                </Modal>
              </>
            )}
          </Tabs.Panel>
        </Tabs>
      </Box>
    </Box>
  );
};

export default Statistics;
