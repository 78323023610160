import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { TaskDto, UserRole } from "../dto/user";
import { getTasks } from "../apiRoutes";
import { useApi } from "../useApi";
import { UserContext } from "../UserContext";

interface TasksContextType {
  tasks: TaskDto[];
  setTasks: (tasks: TaskDto[]) => void;
  refreshTasks: () => Promise<void>;
  loading: boolean;
}

const TasksContext = createContext<TasksContextType | undefined>(undefined);

export const TasksProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [tasks, setTasks] = useState<TaskDto[]>([]);
  const [loading, setLoading] = useState(true);
  const api = useApi();
  const { user } = useContext(UserContext);

  const shouldLoadTasks =
    user?.role === UserRole.administrateur || user?.role === UserRole.observer;

  const refreshTasks = useCallback(async () => {
    if (!shouldLoadTasks) return;

    try {
      const loadRecentTasks =
        localStorage.getItem("loadRecentTasks") !== "false";
      const { data } = await getTasks(api, loadRecentTasks);
      setTasks(data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    } finally {
      setLoading(false);
    }
  }, [api, shouldLoadTasks]);

  // Ajout de l'intervalle de rafraîchissement
  useEffect(() => {
    if (!shouldLoadTasks) return;

    // Premier chargement
    refreshTasks();

    // Mettre en place l'intervalle de rafraîchissement (60000ms = 1 minute)
    const interval = setInterval(() => {
      refreshTasks();
    }, 60000);

    // Nettoyer l'intervalle lors du démontage du composant
    return () => clearInterval(interval);
  }, [refreshTasks, shouldLoadTasks]);

  return (
    <TasksContext.Provider
      value={{
        tasks: shouldLoadTasks ? tasks : [],
        setTasks,
        refreshTasks,
        loading,
      }}
    >
      {children}
    </TasksContext.Provider>
  );
};

export const useTasks = () => {
  const context = useContext(TasksContext);
  if (!context) {
    throw new Error("useTasks must be used within a TasksProvider");
  }
  return context;
};
