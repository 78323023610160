import {
  createStyles,
  // Menu,
  // Center,
  Header,
  Container,
  Group,
  Button,
  // Burger,
  rem,
  Text,
  Menu,
  Image,
  Switch,
  Tooltip,
} from "@mantine/core";
import { logout } from "../apiRoutes";
import { resetApiInstance, useApi } from "../useApi";
import { UserContext } from "../UserContext";
import { useContext, useState } from "react";
import {
  IconChartArrowsVertical,
  IconFeather,
  IconKey,
  IconLogout,
  IconWeight,
} from "@tabler/icons-react";
import { IconDotsVertical, IconLifeBoat } from "./IconType";
import HelpModal from "./secondary/HelpModal";
import NewPasswordModal from "./secondary/NewPasswordModal";
import { useNavigate } from "react-router-dom";
import { useViewportSize } from "@mantine/hooks";
import { UserRole } from "../dto/user";
import { useTasks } from "../contexts/TasksContext";
// import { useDisclosure } from "@mantine/hooks";
// import { IconChevronDown } from "@tabler/icons-react";

const HEADER_HEIGHT = rem(76);

const useStyles = createStyles((theme) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid #D0D5DD`,
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  linkLabel: {
    marginRight: rem(5),
  },
}));

// interface HeaderActionProps {
//   links: {
//     link: string;
//     label: string;
//     links: { link: string; label: string }[];
//   }[];
// }

const HeaderPlanningLinks = () => {
  const navigate = useNavigate();

  return (
    <Button.Group>
      <Button variant="default" onClick={() => navigate("/enregistrements")}>
        Enregistrements
      </Button>
      <Button variant="default" onClick={() => navigate("/reunions")}>
        Réunions
      </Button>
      <Button variant="default" onClick={() => navigate("/planning")}>
        Planning
      </Button>
      <Button variant="default" onClick={() => navigate("/clients")}>
        Clients
      </Button>
      <Button variant="default" onClick={() => navigate("/redacteurs")}>
        Rédacteurs
      </Button>
    </Button.Group>
  );
};

export function HeaderAction({ planning }: { planning?: boolean }) {
  const [openHelpModal, setOpenHelpModal] = useState(false);
  const [openNewPasswordModal, setOpenNewPasswordModal] = useState(false);
  const [loadRecentTasks, setLoadRecentTasks] = useState(() => {
    const savedValue = localStorage.getItem("loadRecentTasks");
    return savedValue === null ? false : savedValue === "true";
  });
  const { refreshTasks } = useTasks();
  const { classes } = useStyles();
  const { user } = useContext(UserContext);
  const api = useApi();
  const navigate = useNavigate();
  const { width } = useViewportSize();

  const handleLogout = async () => {
    try {
      await logout(api);
      localStorage.removeItem("user");
      resetApiInstance(); // Réinitialiser l'instance API
      window.location.href = "/login";
    } catch (error) {
      console.error("Failed to logout", error);
      // En cas d'erreur, on nettoie quand même
      localStorage.removeItem("user");
      resetApiInstance();
      window.location.href = "/login";
    }
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.checked;
    setLoadRecentTasks(newValue);
    localStorage.setItem("loadRecentTasks", newValue.toString());
    refreshTasks();
  };

  // const [opened, { toggle }] = useDisclosure(false);
  // const items = links.map((link) => {
  //   const menuItems = link.links?.map((item) => (
  //     <Menu.Item key={item.link}>{item.label}</Menu.Item>
  //   ));

  //   if (menuItems) {
  //     return (
  //       <Menu
  //         key={link.label}
  //         trigger="hover"
  //         transitionProps={{ exitDuration: 0 }}
  //         withinPortal
  //       >
  //         <Menu.Target>
  //           <a
  //             href={link.link}
  //             className={classes.link}
  //             onClick={(event) => event.preventDefault()}
  //           >
  //             <Center>
  //               <span className={classes.linkLabel}>{link.label}</span>
  //               <IconChevronDown size={rem(12)} stroke={1.5} />
  //             </Center>
  //           </a>
  //         </Menu.Target>
  //         <Menu.Dropdown>{menuItems}</Menu.Dropdown>
  //       </Menu>
  //     );
  //   }

  //   return (
  //     <a
  //       key={link.label}
  //       href={link.link}
  //       className={classes.link}
  //       onClick={(event) => event.preventDefault()}
  //     >
  //       {link.label}
  //     </a>
  //   );
  // });

  return (
    <Header height={HEADER_HEIGHT} sx={{ borderBottom: 0 }}>
      <Container className={classes.inner} fluid>
        <Group>
          {/* <Burger
            opened={opened}
            onClick={toggle}
            className={classes.burger}
            size="sm"
          /> */}
          {width > 600 && (
            <Image
              src="/LOGOhorizontal-transparent_noir.png"
              alt="Logo"
              width={120}
              style={{ marginLeft: 20, marginRight: 20, cursor: "pointer" }}
              onClick={() => navigate("/")}
            />
          )}
          {width > (user?.role === "administrateur" ? 1450 : 1030) &&
            !planning && (
              <Text size={24}>Bienvenue dans votre espace {user?.role}</Text>
            )}
        </Group>
        {/* <Group spacing={5} className={classes.links}>
          {items}
        </Group> */}
        {/* <Button radius="xl" h={30} onClick={handleLogout}>
          Se déconnecter
        </Button> */}
        <HelpModal setOpened={setOpenHelpModal} opened={openHelpModal} />
        <NewPasswordModal
          setOpened={setOpenNewPasswordModal}
          opened={openNewPasswordModal}
        />
        <div style={{ display: "flex", gap: 20 }}>
          <Tooltip label="Charger seulement les tâches datant de moins de 2 mois ou toutes les tâches">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Switch
                checked={loadRecentTasks}
                onChange={handleSwitchChange}
                size="md"
                thumbIcon={
                  loadRecentTasks ? (
                    <IconFeather size="0.8rem" color="green" stroke={3} />
                  ) : (
                    <IconWeight size="0.8rem" color="red" stroke={3} />
                  )
                }
              />
            </div>
          </Tooltip>
          {user?.role === "administrateur" && <HeaderPlanningLinks />}
          {user?.role !== "client" ? null : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setOpenHelpModal(true)}
            >
              {IconLifeBoat}
              <div style={{ marginLeft: 7 }}>Aide</div>
            </div>
          )}
          <Menu trigger="hover" openDelay={100} closeDelay={400}>
            <Menu.Target>
              <Button variant="default" rightIcon={IconDotsVertical}>
                {user?.username}
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                icon={<IconKey size={14} />}
                onClick={() => setOpenNewPasswordModal(true)}
              >
                Modifier mot de passe
              </Menu.Item>
              <Menu.Item icon={<IconLogout size={14} />} onClick={handleLogout}>
                Se déconnecter
              </Menu.Item>
              {user?.role === UserRole.administrateur && (
                <Menu.Item
                  icon={<IconChartArrowsVertical size={14} />}
                  onClick={() => navigate("/statistiques")}
                >
                  Statistiques
                </Menu.Item>
              )}
            </Menu.Dropdown>
          </Menu>
        </div>
      </Container>
    </Header>
  );
}
