import React, { useState, useRef } from "react";
import { HeaderAction } from "../components/HeaderAction";
import { ActionIcon, Box, Flex, Title, Loader, Center } from "@mantine/core";
import { TaskDto } from "../dto/user";
import { useApi } from "../useApi";
import { getTasksMember } from "../apiRoutes";
import moment, { Moment } from "moment";
import Legend from "../components/calendar/Legend";
import DayCell from "../components/DayCell";
import { capitalizeFirstLetter, generateDays } from "../utils";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import DayCellTitle from "../components/DayCellTitle";
import { InOfficeDay } from "../types";

const MemberCalendar = () => {
  const [refresh, setRefresh] = useState<number>(0);
  const [tasks, setTasks] = useState<TaskDto[]>([]);
  const [days, setDays] = useState<Moment[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [inOfficeDaysLoading, setInOfficeDaysLoading] = useState<boolean>(true);
  const api = useApi();
  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];
  const [currentMonth, setCurrentMonth] = useState(moment());
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const dateRefs = useRef<{ [key: string]: HTMLDivElement }>({});
  const [inOfficeDays, setInOfficeDays] = useState<InOfficeDay[]>([]);

  React.useEffect(() => {
    setDays(generateDays(currentMonth));
  }, [currentMonth]);

  React.useEffect(() => {
    const fetchTasks = async () => {
      setLoading(true);
      try {
        const { data }: { data: TaskDto[] } = await getTasksMember(api);
        console.log("tasks", data);
        setTasks(data);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTasks();
  }, [api, refresh]);

  React.useEffect(() => {
    const fetchInOfficeDays = async () => {
      setInOfficeDaysLoading(true);
      try {
        const response = await api.get("/inofficedates/me");
        const userDays = response.data.map((day: any) => ({
          date: day.date.split("T")[0],
          type: day.type,
        }));
        setInOfficeDays(userDays);
      } catch (error) {
        console.error("Error fetching inOfficeDays:", error);
      } finally {
        setInOfficeDaysLoading(false);
      }
    };
    fetchInOfficeDays();
  }, [api, refresh]);

  // Fonction pour trouver l'inOfficeDay correspondant à un jour spécifique
  const findInOfficeDayForDate = (dayMoment: Moment) => {
    const dayStr = dayMoment.format("YYYY-MM-DD");
    return inOfficeDays.find((day) => day.date === dayStr);
  };

  // @ts-ignore
  const filterTasksForDay = (day) => {
    const dayStr = day.format("YYYY-MM-DD");
    return tasks.filter((task) => {
      // @ts-ignore
      const startDateStr = task?.startDate?.split("T")[0];
      // @ts-ignore
      const endDateStr = task?.endDate?.split("T")[0] || startDateStr;
      return startDateStr <= dayStr && dayStr <= endDateStr;
    });
  };

  React.useEffect(() => {
    const currentDayKey = moment(today).format("YYYY-MM-DD");
    if (dateRefs.current[currentDayKey]) {
      const element = dateRefs.current[currentDayKey];
      const offsetLeft = element.offsetLeft;
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTo({
          left: offsetLeft - 34,
          behavior: "smooth",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days]);

  return (
    <Box bg={"#F9FAFB"} pb={1}>
      <HeaderAction planning />
      <Box
        style={{
          backgroundColor: "white",
          border: "1px solid #EAECF0",
          borderTopRightRadius: 12,
          borderTopLeftRadius: 12,
        }}
        mr={16}
        ml={16}
        mt={16}
        p={24}
      >
        <Flex gap={10} mb={4}>
          <ActionIcon
            variant="default"
            onClick={() =>
              setCurrentMonth(currentMonth.clone().subtract(1, "months"))
            }
          >
            <IconChevronLeft size="1rem" />
          </ActionIcon>
          <Title mb={5} mt={2}>
            Planning {capitalizeFirstLetter(currentMonth.format("MMMM YYYY"))}
          </Title>
          <ActionIcon
            variant="default"
            onClick={() =>
              setCurrentMonth(currentMonth.clone().add(1, "months"))
            }
          >
            <IconChevronRight size="1rem" />
          </ActionIcon>
        </Flex>
        <Legend />
      </Box>
      {loading || inOfficeDaysLoading ? (
        <Center my={50}>
          <Loader size="lg" />
        </Center>
      ) : (
        <div
          ref={scrollContainerRef}
          style={{
            display: "flex",
            margin: "0px 16px 16px 16px",
            backgroundColor: "white",
            borderLeft: "1px solid #EAECF0",
            overflowX: "auto",
          }}
        >
          {days.map((day) => {
            const dayStr = day.format("YYYY-MM-DD");
            const tasksForDay = filterTasksForDay(day);
            const inOfficeDayForThisDate = findInOfficeDayForDate(day);

            return (
              <div
                key={dayStr}
                ref={(el) => {
                  if (el) dateRefs.current[dayStr] = el;
                }}
              >
                <DayCellTitle
                  day={day}
                  formattedToday={formattedToday}
                  isMemberCalendar
                />
                <DayCell
                  day={day}
                  tasks={tasksForDay}
                  isMembers
                  setRefresh={setRefresh}
                  inOfficeDay={inOfficeDayForThisDate}
                />
              </div>
            );
          })}
        </div>
      )}
    </Box>
  );
};

export default MemberCalendar;
